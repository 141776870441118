import React from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  Stack,
  SwipeableDrawer,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import { getSrcFromApi } from "../common/Image";
import {
  InvertedIconButton,
  LoadedAvatar,
  StyledTextfield,
} from "../common/StyledComponents";
import axios from "axios";
import { rule5AppUrl, rule5properties } from "../properties";
import {
  COREAPP_USER_ROLES,
  PLAN_TYPES,
  useUser,
} from "../context/UserContext";
import JwtService from "../user/JwtService";
import { useImmer } from "use-immer";
import DotsLoader from "react-dots-loader";
import "react-dots-loader/index.css";
import { MemoizedChatHistory } from "./chat/ChatHistory";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRight from "@mui/icons-material/ChevronRight";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ChatCompaniesAutocomplete from "./chat/ChatCompaniesAutocomplete";
import ChatLandingPage from "./chat/ChatLandingPage";
import { MemoizedChatMessage } from "./chat/ChatMessage";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useGptConversationContext } from "../context/GptConversationContext";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDialog } from "../context/DialogContext";
import GenerateEmail from "../modal/GenerateEmail";
import { useHistory, Link, useParams } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { cloneDeep, isArray, isEmpty } from "lodash";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import AuthService from "../user/AuthService";
import PromptBooksAutocomplete from "./chat/PromptBooksAutocomplete";
import ChatActionsMenu from "./chat/ChatActionsMenu";
import { useForm } from "react-hook-form";
import { useDisplayContext } from "../context/DisplayContext";
import BusinessIcon from "@mui/icons-material/Business";
import ContentsChip from "./chat/ContentsChip";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Gpt from "../settings/Gpt";
import UploadContent from "../modal/UploadContent";
import CardTypesChip, {
  DEFAULT_CARD_TYPES_OBJ,
  getSelectedCardTypesFromConversationPrompt,
} from "./chat/CardTypesChip";
import useSnack from "../context/Snack";
import { isSuccessStatus } from "../common/RequestUtils";
import { useOpportunityCompanies } from "../api/opportunities";

const useStyles = makeStyles(() => ({
  chatContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
  },
  chatMessages: {
    height: "100%",
    width: "100%",
    position: "absolute",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    opacity: 1,
    transition: "0.5s",
  },
  pageTitleInChat: {
    cursor: "pointer",
    opacity: 0.5,
    transition: "0.2s",
    "&:hover": {
      opacity: 1,
      color: "rgb(25, 118, 210)",
      marginLeft: "-4px",
    },
  },
  chatForm: {
    padding: "3rem 1rem 2rem 1rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,0) 0%,#fff  14%)",
    position: "absolute",
    bottom: 0,
    // TODO will add back once endpoint ready
    // "&:hover": {
    //   "& $stopButton": {
    //     visibility: "visible",
    //     width: "auto",
    //   },
    //   "& $dotsLoader": {
    //     visibility: "hidden",
    //     width: 0,
    //   },
    // },
  },
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: (props) => (props.historyOpen ? `calc(100% - 300px)` : "100%"),
  },
  chatTitle: {
    opacity: (props) => (props.company ? 1 : 0),
    transition: "0.25s",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stopButton: {
    visibility: "hidden",
    width: 0,
  },
  dotsLoader: {
    width: "auto",
  },
  chatActionButton: {
    textTransform: "none",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 1.5,
    backgroundColor: "#1976D2", //primary.main
    color: "white",
    "&:hover": {
      backgroundColor: "#1565c0", //primary.dark
    },
    "&:focus": {
      backgroundColor: "#42a5f5", //primary.light
    },
  },
  historyContainer: {
    width: 300,
    "& .MuiDrawer-paper": {
      width: 300,
      zIndex: 1,
      overflow: "inherit",
    },
  },
  mobileActionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "30px",
    marginTop: "10px",
    padding: "15px",
    backgroundColor: "white",
  },
}));

export const EMPTY_CONVERSATION = {
  id: null,
  promptCompletion: [],
  company: null,
  functionalArea: null,
  suggestedQuestions: null,
};

export default function WSChat() {
  const [userImage, setUserImage] = React.useState(null);
  const [historyOpen, setHistoryOpen] = React.useState(isMobile ? false : true);
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [conversation, setConversation] = useImmer(EMPTY_CONVERSATION);
  const [opportunityId, setOpportunityId] = React.useState(null);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [promptBooksAnchorEl, setPromptBooksAnchorEl] = React.useState(null);
  const [chatTitleHover, setChatTitleHover] = React.useState(false);
  const [formHeight, setFormHeight] = React.useState(0);
  const [chatActionsAnchorEl, setChatActionsAnchorEl] = React.useState(null);
  const [selectedContents, setSelectedContents] = React.useState([]);
  const [selectedCardTypes, setSelectedCardTypes] = React.useState(
    DEFAULT_CARD_TYPES_OBJ
  );
  const [contentUserCompanyId, setContentUserCompanyId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const chatActionsOpen = Boolean(chatActionsAnchorEl);

  const gptConversationContext = useGptConversationContext();
  const displayContext = useDisplayContext();
  const dialog = useDialog();
  const history = useHistory();
  const { conversationId } = useParams();
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const classes = useStyles({
    historyOpen: historyOpen,
    company: conversation?.company,
  });
  const user = useUser();
  const snackBar = useSnack();
  const lastMessageRef = React.useRef();
  const emailCompletionRef = React.useRef();
  const { ref: registerPromptInputRef, ...registerPromptInput } =
    register("promptInput");
  const promptInputRef = React.useRef(null);
  const { data: userOpportunityCompanies } = useOpportunityCompanies();

  React.useEffect(() => {
    if (user.userSettingInfo?.profilePicture) {
      getSrcFromApi(user.userSettingInfo?.profilePicture, user).then((image) =>
        setUserImage(image)
      );
    }
  }, []);

  React.useEffect(() => {
    let lastPrompt = conversation.promptCompletion?.at(-1)?.prompt;
    // GenerateEmail prompts are a nested array for some reason
    if (isArray(lastPrompt)) {
      lastPrompt = lastPrompt.at(-1);
    }
    const contents = lastPrompt?.contents?.map((content) => {
      let newContent = cloneDeep(content);
      newContent.contentId = parseInt(newContent.contentId); // it's a string if from backend conversation endpoint
      return newContent;
    });

    //todo as per Kalai: If the last executed prompt action is generateEmail,
    //then there won't be opportunityId, so can't get any cardTypes.
    //so you may need to check what was the last userPrompt action to get the cardTypes.

    setSelectedContents(contents ? contents : []);
    setSelectedCardTypes(
      getSelectedCardTypesFromConversationPrompt(lastPrompt)
    );
  }, [conversation?.id]); // Reset selection when conversation changes

  // Get the opportunity id for this research
  React.useEffect(() => {
    if (!userOpportunityCompanies) {
      return;
    }

    if (conversation.company?.companyId && conversation.functionalArea) {
      const userOpptiesForCompany = userOpportunityCompanies.find(
        (company) => company.companyId === conversation.company.companyId
      ).oppties;
      let opptyId = userOpptiesForCompany.find(
        (oppty) => oppty.functionalArea === conversation.functionalArea
      )?.id;

      setOpportunityId(opptyId);
    } else {
      setOpportunityId(null);
    }
  }, [conversation?.company, userOpportunityCompanies]);

  /** Splits the given string into an array of json strings */
  function splitWithoutLookbehind(input) {
    const regex = /}(?={)/g;
    let lastIndex = 0;
    const result = [];
    let match;

    while ((match = regex.exec(input)) !== null) {
      result.push(input.slice(lastIndex, match.index + 1));
      lastIndex = match.index + 1;
    }

    result.push(input.slice(lastIndex));
    return result;
  }

  const processingPromptCompletion = conversation.promptCompletion?.find(
    (pc) => pc.isProcessing === true
  );

  const scrollToBottom = () => {
    // Feel like this should be doable with only one ref instead of two, but oh well
    if (emailCompletionRef.current) {
      emailCompletionRef.current.scrollIntoView({ block: "center" });
    } else {
      lastMessageRef.current?.scrollIntoView(false);
    }
  };

  // deprecated
  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (!bottom && displaySuggestedQuestions) {
  //     setDisplaySuggestedQuestions(false);
  //   } else if (bottom && !displaySuggestedQuestions) {
  //     setDisplaySuggestedQuestions(true);
  //   }
  // };

  /** Submits form if enter key is pressed (and not shift key, which would make a newline) */
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleChatActionsClick = (event) => {
    setChatActionsAnchorEl(event.currentTarget);
  };
  const handleChatActionsClose = () => {
    setChatActionsAnchorEl(null);
  };

  const handlePromptBooksClick = (event) => {
    setPromptBooksAnchorEl(event.currentTarget);
  };

  const handlePromptBooksClose = () => {
    setPromptBooksAnchorEl(null);
    // Focus the chat box.
    promptInputRef.current?.focus();
  };

  const setPromptText = (prompt) => {
    setValue("promptInput", prompt);
    promptInputRef.current?.focus();
  };

  const promptBooksOpen = Boolean(promptBooksAnchorEl);

  const formRef = React.useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setFormHeight(node.getBoundingClientRect().height);
    });
    resizeObserver.observe(node);
  }, []);

  React.useLayoutEffect(() => {
    scrollToBottom();
  }, [processingPromptCompletion, conversation.id, formHeight]);

  function onSubmit(data, event) {
    if (generateEmailInitialSelectionMode) {
      openEmailDialog();
    } else {
      const text = data.promptInput;
      if (text === "") {
        return;
      }
      if (isContentUser && isEmpty(selectedContents)) {
        snackBar.createSnack("At least one piece of content is required.");
        return;
      }
      const prompt = { prompt: text, action: "userPrompt" };
      handleNewPrompt({ requestFields: prompt }); // For this case req fields and ChatMessage prompt are the same
    }
  }

  // temp
  React.useEffect(() => {
    getUserOrgAsCompanyId();
  }, []);

  function getUserOrgAsCompanyId() {
    if (isContentUser) {
      axios.get(rule5properties.orgDetails).then((resp) => {
        axios
          .get(`${rule5properties.companies}?name=${resp?.data?.name}`)
          .then((resp) => {
            if (isSuccessStatus(resp.status)) {
              setContentUserCompanyId(resp.data[0]?.id);
            }
          });
      });
    }
  }

  /* messagePrompt is the JSON that will be used based on action type. I.e. basic prompt will be.
      {
        prompt: "etc etc",
        actionType: "userPrompt"
    }
    */
  function handleNewPrompt({
    requestFields,
    messagePrompt = requestFields, // Should be set explicitly for generateEmail action since history needs to be included and also prompt structure doesn't match the requestFields structure in generateEmail case
    company, // Should be included if company not set prior to selecting prompt
    functionalArea,
    // Below fields for email feature which has more complex structure to support editing/regenerating
    activePromptIndex,
    activeCompletionIndex,
    completion,
  }) {
    if (processingPromptCompletion || !requestFields) {
      return;
    }

    // Empty the prompt field.
    reset();

    let newText = ""; // TODO probably can remove this newText and just the value from state when needed
    let conversationIdFromResponse = null;
    let promptCompletionId = null;
    let suggestedQuestions = null;
    let relevantCards = null;
    let promptIndex = null;
    if (requestFields.existingPromptCompletionId) {
      promptIndex = conversation.promptCompletion.findIndex(
        (pc) => pc.promptId === requestFields.existingPromptCompletionId
      );
    } else {
      promptIndex = conversation.promptCompletion.length;
    }

    function getUpdatedCompletionText(completion, toAppend) {
      if (requestFields.action === "generateEmail") {
        const newArray = cloneDeep(completion[activePromptIndex]);
        newArray[activeCompletionIndex] += toAppend;

        completion = {
          ...completion,
          [activePromptIndex]: newArray,
        };
        return completion;
      }

      return (completion ? completion : "") + toAppend;
    }

    // TODO clean up; a bit awkward how request is built from a mix of state and params
    const additionalRequestFields = {
      ...(selectedContents?.length > 0
        ? {
            contentIds: selectedContents.map((content) => content.contentId),
          }
        : { contentIds: [] }), // Passing empty array if none selected
      ...(selectedCardTypes.types?.length > 0
        ? {
            cardTypes: selectedCardTypes.types,
          }
        : !isContentUser
        ? { cardTypes: [] }
        : {}),
      ...(requestFields.action === "userPrompt" && !isContentUser
        ? { excludeAllCards: selectedCardTypes.excludeAll }
        : {}),
      //temp till companyId is optional
      ...(isContentUser
        ? { excludeAllCards: true, companyId: contentUserCompanyId?.toString() }
        : {}),
    };

    const newPromptCompletionFields = {
      prompt:
        requestFields.action === "generateEmail"
          ? messagePrompt
          : {
              ...messagePrompt,
              ...additionalRequestFields,
              contents: selectedContents,
            }, // TODO, clean all this up.
      completion: completion,
      isProcessing: true,
      unclosedTags: [],
    };

    setConversation((draft) => {
      if (!requestFields.existingPromptCompletionId) {
        draft.promptCompletion.push(newPromptCompletionFields);
      } else {
        draft.promptCompletion[promptIndex] = {
          ...draft.promptCompletion[promptIndex],
          ...newPromptCompletionFields,
        };
      }
      draft.suggestedQuestions = null;

      if (functionalArea) {
        draft.functionalArea = functionalArea;
      }
      if (company) {
        draft.company = company;
      }
    });

    const params = {
      // If active conversation doesn't have companyId or functionalArea then it should be
      // included in request fields.
      ...(conversation?.company?.companyId
        ? { companyId: conversation?.company?.companyId.toString() }
        : {}),
      ...(conversation.functionalArea
        ? { functionalArea: conversation.functionalArea }
        : {}),
      ...(conversation.id
        ? { conversationId: conversation.id.toString() } // Id is present if existing conversation
        : {}),
      ...(opportunityId && requestFields.action !== "generateEmail"
        ? { opportunityId: opportunityId.toString() } // opportunityId is present if prompt came from WSChat's submit
        : {}),
      ...additionalRequestFields,
      ...requestFields,
    };

    const signal = gptConversationContext.controller.signal; // for the abortcontroller, doesn't really work in that backend keeps generating.

    fetch(rule5properties.gptCompletion, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JwtService.getToken(),
        Accept: "text/event-stream",
      },
      body: JSON.stringify(params),
      signal,
    })
      .then((response) => {
        if (response.status === 200) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");

          // Docs on parser's handler functions: https://github.com/fb55/htmlparser2
          const htmlparser2 = require("htmlparser2");
          const parser = new htmlparser2.Parser({
            onopentag(name, attributes) {
              let attributesStr = "";
              Object.entries(attributes)?.forEach(
                ([key, value]) => (attributesStr += ` ${key}="${value}"`)
              );
              setConversation((draft) => {
                if (draft.promptCompletion[promptIndex]?.isProcessing) {
                  draft.promptCompletion[promptIndex].completion =
                    getUpdatedCompletionText(
                      draft.promptCompletion[promptIndex]?.completion,
                      "<" + name + attributesStr + ">"
                    );
                  draft.promptCompletion[promptIndex].unclosedTags?.push(name);
                }
              });
            },
            ontext(text) {
              setConversation((draft) => {
                if (draft.promptCompletion[promptIndex]?.isProcessing) {
                  // Might not be processing in case user bounces around between conversations during generation.
                  draft.promptCompletion[promptIndex].completion =
                    getUpdatedCompletionText(
                      draft.promptCompletion[promptIndex]?.completion,
                      text
                    );
                }
              });
            },
            onclosetag(tagname) {
              setConversation((draft) => {
                if (draft.promptCompletion[promptIndex]?.isProcessing) {
                  draft.promptCompletion[promptIndex].completion =
                    getUpdatedCompletionText(
                      draft.promptCompletion[promptIndex]?.completion,
                      "</" + tagname + ">"
                    );
                  draft.promptCompletion[promptIndex].unclosedTags?.splice(
                    draft.promptCompletion[promptIndex].unclosedTags?.indexOf(
                      tagname
                    ),
                    1
                  );
                }
              });
            },
            onend() {
              setConversation((draft) => {
                if (conversationIdFromResponse) {
                  draft.id = conversationIdFromResponse;
                  history.push(
                    `/main/chat/${conversationIdFromResponse}?new=true`
                  );
                }
                if (draft.promptCompletion[promptIndex]) {
                  draft.promptCompletion[promptIndex].isProcessing = false;
                  // Add in the new id from response if it's a new message rather than edit/regenerate
                  if (promptCompletionId) {
                    draft.promptCompletion[promptIndex].promptId =
                      promptCompletionId;
                  }
                  if (relevantCards) {
                    draft.promptCompletion[promptIndex].prompt.relevantCards =
                      relevantCards;
                  }
                }

                if (suggestedQuestions) {
                  draft.suggestedQuestions = suggestedQuestions;
                } else {
                  draft.suggestedQuestions = null;
                }
              });
            },
          });

          reader.read().then(function processStream({ value, done }) {
            if (done) {
              parser.parseComplete();
              return;
            }
            try {
              const decodedString = decoder.decode(value);
              // Being sure not to use a look behind assertion,
              // which for some reason Safari does not work with.
              let decodedStringArray = splitWithoutLookbehind(decodedString); //decodedString.split(/(?<=})(?={)/g);
              const nextTokens = decodedStringArray.map((value) =>
                JSON.parse(value)
              );
              nextTokens.forEach((nextToken) => {
                if (nextToken.token) {
                  newText = newText + nextToken.token;
                  parser.write(nextToken.token);
                }
                if (nextToken.conversationId) {
                  conversationIdFromResponse = parseInt(
                    nextToken.conversationId
                  );
                }
                if (nextToken.promptCompletionId) {
                  promptCompletionId = parseInt(nextToken.promptCompletionId);
                }
                if (nextToken.suggestedQuestions) {
                  suggestedQuestions = nextToken.suggestedQuestions;
                }
                if (nextToken.prompt) {
                  relevantCards = nextToken.prompt?.relevantCards;
                }
              });
            } catch (e) {
              console.log("Error parsing JSON: " + e);
            }

            return reader
              .read()
              .then(processStream)
              .catch((error) => console.log(error)); // possibly abort was called mid stream
          });
        } else {
          // Response other than 200 OK
          return response.json().then((responseJson) => {
            let errorMessage =
              "Sorry, we encountered an error with our system. " +
              "Please try again later or contact our support team if the problem persists.";
            if (responseJson.code === "INVALID_ARGUMENT") {
              errorMessage = responseJson.message;
            }
            setConversation((draft) => {
              draft.promptCompletion[promptIndex].error = errorMessage;
              draft.promptCompletion[promptIndex].isProcessing = false;
              draft.promptCompletion[promptIndex].unclosedTags = null;
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function openEmailDialog() {
    dialog.openModal(
      "Generate Email",
      GenerateEmail,
      {
        selectedCompletions: gptConversationContext.selection.selectedMessages,
        conversationId: conversation.id,
        clearSelectedCompletions: () => {
          gptConversationContext.resetSelection();
        },
        handleNewPrompt: handleNewPrompt,
      },
      "md"
    );
  }

  function openGptSettingsDialog() {
    dialog.openModal(null, Gpt, null, "sm");
  }

  const drawerOpenIcon = isMobile ? (
    <IconButton
      onClick={() => setMobileDrawerOpen(true)}
      sx={{ width: "32px", height: "32px", margin: "auto" }}
    >
      <MenuIcon sx={{ transform: "rotate(180deg)" }} />
    </IconButton>
  ) : (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={() => setHistoryOpen((prevState) => !prevState)}
      sx={{
        width: "40px",
        height: "40px",
        margin: "9px -20px 9px 9px",
        ...(historyOpen && { display: "none" }),
      }}
    >
      <MenuIcon />
    </IconButton>
  );

  const chatHistory = (
    <MemoizedChatHistory
      userOpportunityCompanies={userOpportunityCompanies}
      setOpenAutocomplete={setOpenAutocomplete}
      setHistoryOpen={setHistoryOpen}
      selectedConversationId={conversation.id ? conversation.id : -1} // -1 is just experimenting with extra renders of history conversationitems
      setConversation={setConversation}
      setLoading={setLoading}
    ></MemoizedChatHistory>
  );

  const generateEmailInitialSelectionMode =
    gptConversationContext.selection.selectionMode &&
    gptConversationContext.selection.activePromptId === null;

  const isContentUser =
    user.userSubscription?.planType === PLAN_TYPES.premiumContent;
  const displayForm = conversation.company || isContentUser;

  return (
    <div id="focusout">
      <Toolbar endChild={drawerOpenIcon} historyOpen={historyOpen}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100% - 32px)",
          }}
        >
          {displayContext.presetCompany.opportunityId && (
            <Button
              startIcon={<BusinessIcon />}
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                fontSize: "15px",
                margin: "16px auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderRadius: "8px",
                width: "200px",
              }}
              onClick={() => {
                history.push(
                  `/main/opportunities/${displayContext.presetCompany.opportunityId}/research`
                );
              }}
            >
              <Typography sx={{ textTransform: "none", fontWeight: 500 }}>
                Switch to AccountIQ
              </Typography>
            </Button>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              className={
                conversation === EMPTY_CONVERSATION
                  ? classes.pageTitle
                  : classes.pageTitleInChat
              }
              onMouseEnter={
                displayContext.presetCompany.companyId
                  ? null
                  : () => {
                      setChatTitleHover(true);
                    }
              }
              onMouseLeave={() => {
                setChatTitleHover(false);
              }}
              onClick={
                displayContext.presetCompany.companyId
                  ? () => {
                      window.open(rule5AppUrl, "_blank");
                    }
                  : () => {
                      if (history.location.pathname !== "/main/chat") {
                        history.push(`/main/chat`);
                      } else {
                        setConversation(EMPTY_CONVERSATION);
                      }
                    }
              }
            >
              Action IQ
            </div>
            {displayContext.displayMode !== "iframe" && !loading && (
              <>
                <ChevronRight
                  className={classes.chatTitle}
                  sx={{
                    mx: 1.5,
                    transform: chatTitleHover ? "scaleX(-1)" : "none",
                  }}
                />
                <span
                  className={classes.chatTitle}
                  style={{ opacity: chatTitleHover ? 0 : 1 }}
                >
                  {` ${
                    conversation.company?.companyInfo?.name
                      ? conversation.company?.companyInfo?.name
                      : ""
                  }${
                    conversation.functionalArea &&
                    conversation.functionalArea !== "Global"
                      ? " - " + conversation.functionalArea
                      : ""
                  }`}
                </span>
              </>
            )}
          </div>
        </div>
        <Box sx={{ alignSelf: "center" }}>
          <Tooltip title="GPT Preferences">
            <IconButton onClick={openGptSettingsDialog}>
              <SettingsSuggestIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <Workspace>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            width: historyOpen
              ? `calc(100% - ${theme.chatHistory?.width}px)`
              : "100%",
          })}
        >
          <div className={classes.chatContainer}>
            {/* {loading ? (
              <CircularProgress sx={{ margin: "35vh auto 0 auto" }} />
            ) : ( */}
            <div
              className={classes.chatMessages}
              // onScroll={handleScroll} commenting out since suggestedQuestions removed
            >
              {!conversationId &&
              !processingPromptCompletion &&
              conversation.promptCompletion?.length === 0 ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "20px",
                    placeContent: "start",
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "15px",
                  }}
                >
                  {!conversationId && (
                    <ChatLandingPage
                      userOpportunityCompanies={userOpportunityCompanies}
                      handleNewPrompt={handleNewPrompt}
                      setPromptText={setPromptText}
                      selectedCompany={conversation.company}
                      functionalArea={conversation.functionalArea}
                    ></ChatLandingPage>
                  )}
                  {!conversation.company && (
                    <ChatCompaniesAutocomplete
                      openAutocomplete={openAutocomplete}
                      setOpenAutocomplete={setOpenAutocomplete}
                      userOpportunityCompanies={userOpportunityCompanies}
                      setConversation={setConversation}
                    ></ChatCompaniesAutocomplete>
                  )}
                </div>
              ) : (
                <>
                  {/* Make sure the user image has loaded before making 
                  the memoized chat message which will not re-render 
                  based on userImage being updated. */}
                  {!(user.userSettingInfo?.profilePicture && !userImage) &&
                    (opportunityId || isContentUser) &&
                    conversation?.promptCompletion?.length > 0 &&
                    conversation?.promptCompletion?.map((message, index) => {
                      return (
                        <div key={`${conversation.id}-${message.promptId}`}>
                          <MemoizedChatMessage
                            message={message}
                            userImage={userImage}
                            handleNewPrompt={handleNewPrompt}
                            opportunityId={opportunityId}
                          />
                          {processingPromptCompletion?.prompt?.[0]?.action ===
                            "generateEmail" &&
                            message.promptId ===
                              processingPromptCompletion?.promptId && (
                              <Box
                                sx={{ height: `0px`, flexShrink: 0 }}
                                ref={emailCompletionRef}
                              ></Box>
                            )}
                        </div>
                      );
                    })}
                </>
              )}
              {displayForm && (
                <Box
                  sx={{
                    height: `${formHeight}px`,
                    flexShrink: 0,
                  }}
                  ref={lastMessageRef}
                ></Box>
              )}
            </div>
            {/* )} */}
            {displayForm && (
              <>
                <form
                  className={classes.chatForm}
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  ref={formRef}
                >
                  <Box
                    sx={{
                      margin: "auto",
                      maxWidth: (theme) => theme.chatBox.width,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Collapse in={generateEmailInitialSelectionMode}>
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          sx={{
                            textTransform: "none",
                            mr: 1,
                            borderRadius: "6px",
                          }}
                          variant="contained"
                          type="submit"
                          disableElevation
                          disableRipple
                          disabled={
                            gptConversationContext.selection.selectedMessages
                              .length === 0
                          }
                        >
                          {gptConversationContext.generateButtonText}
                        </Button>
                        <IconButton
                          aria-label="Cancel generate email"
                          onClick={() => {
                            gptConversationContext.setSelection((draft) => {
                              draft.selectionMode = false;
                              draft.selectedMessages = [];
                            });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Collapse>
                    <Collapse in={!generateEmailInitialSelectionMode}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ flexGrow: 1 }}
                        flexWrap="wrap"
                        rowGap="8px"
                      >
                        <Button
                          className={classes.chatActionButton}
                          onClick={handlePromptBooksClick}
                          sx={{
                            padding: "8px 16px",
                            borderRadius: "8px",
                            backgroundColor: promptBooksOpen
                              ? "#198fd2 !important"
                              : "#1976D2",
                          }}
                          endIcon={
                            <MenuBookIcon
                              fontSize="small"
                              sx={{ ml: 1, color: "white" }}
                            />
                          }
                        >
                          Prompt books
                        </Button>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <CardTypesChip
                            setSelectedCardTypes={setSelectedCardTypes}
                            selectedCardTypes={selectedCardTypes}
                            opportunityId={opportunityId}
                          />
                          <ContentsChip
                            setSelectedContents={setSelectedContents}
                            selectedContents={selectedContents}
                          />
                        </div>
                        <InvertedIconButton
                          aria-label="More options menu"
                          id="chat-menu-button"
                          onClick={handleChatActionsClick}
                        >
                          <MoreVertIcon />
                        </InvertedIconButton>
                        <ChatActionsMenu
                          conversationId={conversation.id}
                          opportunityId={opportunityId}
                          anchorEl={chatActionsAnchorEl}
                          open={chatActionsOpen}
                          handleClose={handleChatActionsClose}
                        ></ChatActionsMenu>
                      </Stack>
                    </Collapse>
                  </Box>
                  <Collapse in={!generateEmailInitialSelectionMode}>
                    <Box
                      sx={{
                        display: "flex",
                        maxWidth: (theme) => theme.chatBox.width,
                        margin: "auto",
                        width: "100%",
                      }}
                    >
                      <StyledTextfield
                        {...registerPromptInput}
                        onInput={(e) => {
                          watch("promptInput"); // This causes an extra render which seems to fix weird bug where after pasting multiline text sometimes, the textarea would not grow. https://rule5io.atlassian.net/browse/R5-675
                          registerPromptInput?.onChange(e);
                        }} // Bug with pasting: https://stackoverflow.com/questions/68919638/react-hook-form-validation-is-not-working-after-paste-value
                        inputRef={(e) => {
                          // https://www.react-hook-form.com/faqs/#Howtosharerefusage
                          registerPromptInputRef(e);
                          promptInputRef.current = e;
                        }}
                        multiline
                        onKeyDown={onEnterPress}
                        placeholder={
                          isContentUser && isEmpty(selectedContents)
                            ? "Click the chip above to select at least one piece of content"
                            : ""
                        }
                        sx={{
                          width: "100%",
                          borderWidth: "2px",
                          maxHeight: "40vh",
                          overflow: "auto",
                          padding: "15px",

                          "& .MuiInputBase-input": {
                            maxHeight: "35vh",
                            overflow: "visible !important", // not sure where "hidden" value on element comes from
                          },
                        }}
                        autoFocus
                        startAdornment={
                          <Tooltip title="Upload content">
                            <IconButton
                              aria-label="Upload content"
                              onClick={() => {
                                dialog.openModal(
                                  "Upload Content",
                                  UploadContent,
                                  {
                                    setSelectedContents: setSelectedContents,
                                    selectedContents: selectedContents,
                                  },
                                  "sm"
                                );
                              }}
                              size="small"
                              color="#525252"
                              opacity={0.7}
                              sx={{ top: "-2px", left: "-4px" }}
                              disabled={!opportunityId && !isContentUser}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        }
                        endAdornment={
                          <InputAdornment
                            sx={{
                              backgroundColor: "none",
                              mr: "4px",
                              opacity: 0.85,
                            }}
                            position="end"
                          >
                            {processingPromptCompletion ? (
                              <div style={{ marginTop: "-6px" }}>
                                <DotsLoader
                                  size={4}
                                  color={"#525252"}
                                  distance={2}
                                  className={classes.dotsLoader}
                                />

                                {/*TODO will add back once endpoint ready <IconButton
                                aria-label="stop processing"
                                type="submit"
                                size="small"
                                className={classes.stopButton}
                                onClick={() => {
                                  if (controller) {
                                    controller.abort();
                                    console.log("Download aborted");
                                    // setConversation((draft) => {
                                    //   draft.processing = DEFAULT_PROCESSING;
                                    // }); TODO idk
                                    setController(new AbortController());
                                  }
                                }}
                              >
                                <StopCircleIcon fontSize="small" />
                              </IconButton> */}
                              </div>
                            ) : (
                              <IconButton
                                aria-label="send prompt to Action IQ"
                                type="submit"
                                size="small"
                                disabled={!opportunityId && !isContentUser}
                              >
                                <SendIcon fontSize="small" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      ></StyledTextfield>
                    </Box>
                  </Collapse>
                </form>
              </>
            )}
          </div>
        </Box>
      </Workspace>
      <BrowserView>
        <Drawer
          variant="persistent"
          anchor="right"
          open={historyOpen}
          className={classes.historyContainer}
        >
          <Box
            sx={(theme) => ({
              width: theme.chatHistory?.width,
              height: "100%",
            })}
            role="presentation"
          >
            {chatHistory}
          </Box>
        </Drawer>
      </BrowserView>
      <MobileView>
        <MobileDrawer
          open={mobileDrawerOpen}
          setOpen={setMobileDrawerOpen}
          chatHistory={chatHistory}
        ></MobileDrawer>
      </MobileView>
      <PromptBooksAutocomplete
        open={promptBooksOpen}
        anchorEl={promptBooksAnchorEl}
        setValue={setValue}
        handleClose={handlePromptBooksClose}
        setSelectedContents={setSelectedContents}
        setSelectedCardTypes={setSelectedCardTypes}
      ></PromptBooksAutocomplete>
    </div>
  );
}

function MobileDrawer(props) {
  const { open, setOpen, chatHistory } = props;
  const classes = useStyles();
  const history = useHistory();
  const user = useUser();

  const handleLogout = (event) => {
    AuthService.logout();
    history.push("/");
  };

  const toggleDrawer = (newOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(newOpen);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      className={classes.historyContainer}
    >
      <Box
        sx={(theme) => ({
          width: theme.chatHistory?.width,
          height: "calc(100% - 80px)",
          overflow: "auto",
        })}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <>{chatHistory}</>
      </Box>
      <Divider />
      <div className={classes.mobileActionsContainer}>
        <LoadedAvatar
          source={
            user.userSettingInfo ? user.userSettingInfo.profilePicture : null
          }
          className={classes.avatar}
        >
          {user.firstName.toUpperCase().charAt(0)}
        </LoadedAvatar>

        <Link style={{ marginRight: "4px" }} to="#" onClick={handleLogout}>
          Log Out
        </Link>
      </div>
    </SwipeableDrawer>
  );
}
