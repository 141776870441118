import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect } from "react";
import ConfigureActions from "../workspaces/monitor/common/ConfigureActions";
import ConfigureSignals from "../workspaces/monitor/common/ConfigureSignals";
import ConfigureAgent from "../workspaces/monitor/common/ConfigureAgent";
import EditAgentList from "../workspaces/monitor/common/EditAgentList";
import { FormProvider, useForm } from "react-hook-form";
import UploadCsv from "../workspaces/monitor/common/UploadCsv";
import { validateEmailString } from "../common/Utils";
import {
  mapKeys,
  invert,
  map,
  cloneDeep,
  isEmpty,
  filter,
  pickBy,
  pick,
  has,
} from "lodash";
import axios from "axios";
import { rule5properties } from "../properties";
import { isSuccessStatus } from "../common/RequestUtils";
import { useDialog } from "../context/DialogContext";
import useSnack from "../context/Snack";
import MonitorService from "../workspaces/monitor/common/monitor_service";
import { accountsAgentType } from "../workspaces/monitor/common/AgentTypes";
import CongratsMessage from "../workspaces/monitor/common/CongratsMessage";
import {
  regionObjectsToStrings,
  signalObjectsToStrings,
} from "../workspaces/monitor/common/monitor_util";
import { useUser } from "../context/UserContext";
import { useOpportunityCompanies } from "../api/opportunities";

const VERIFY_DATA_LABEL = "Data";
const UPLOAD_LABEL = "Upload";

export default function CreateAgent(props) {
  const { agentType, loadAgentRows, hasSyncedResearchAccount } = props;

  const dialog = useDialog();
  const snackBar = useSnack();
  const user = useUser();
  const { data: userOpportunityCompanies } = useOpportunityCompanies();

  const [tempEmail, setTempEmail] = React.useState("");
  const [userMessageChannels, setUserMessageChannels] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [defaultsLoading, setDefaultsLoading] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [response, setResponse] = React.useState(null);
  const [consumptionCount, setConsumptionCount] = React.useState(null);

  const consumptionLimit = user?.userSubscription[agentType.consumptionKey];
  const monitorLimitRemaining = consumptionLimit - consumptionCount;

  const staticDefaults = {
    csvFile: null,
    columnMappings: {},
    rawPayload: null,
    //request fields below
    originalFileName: "",
    payload: null,
    type: agentType.type,
    name: "",
    description: "",
    signals: [],
    regions: [],
    action: {
      agentActionInfo: { notifications: { emails: [] } },
      isEnabled: true,
    },
  };

  React.useEffect(() => {
    axios.get(agentType.endpoint + "/consumption").then((resp) => {
      setConsumptionCount(resp.data?.count);
    });
  }, []);

  const getDefaults = async () => {
    try {
      return MonitorService.getProcessedDefaultValues(
        staticDefaults,
        null,
        setUserMessageChannels
      ).then((defaults) => {
        setDefaultsLoading(false);
        return defaults;
      });
    } catch (err) {
      setDefaultsLoading(false);
      console.log(err);
      snackBar.createSnack("Unexpected error fetching signals.");
      return staticDefaults;
    }
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: getDefaults,
  });

  /** Steps mapping. Validation fields will not be triggered if the step is optional and skipped, even if they are marked as required. */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = [
    {
      label: "Info",
      optional: false,
      component: ConfigureAgent,
      componentProps: {},
      validationFields: ["name", "description"],
    },
    {
      label: UPLOAD_LABEL,
      optional: true,
      component: UploadCsv,
      componentProps: { agentType, hasSyncedResearchAccount },
      validationFields: ["csvFile", "columnMappings", "payload"],
      // User pressing next means the columnMappings are set, so now can convert the raw data into payload
      onNext: () => {
        // If payload has already been set then don't need to transform raw payload.
        const payload = methods.watch("payload");
        // Make sure it's been validated (in case of manual adding flow).
        if (payload) {
          if (!has(payload[0], "isValid")) {
            methods.setValue("payload", agentType.validateRows(payload));
          }
          return;
        }

        // Convert the rawPayload to payload using the mappings. Add in temporary rowIds
        const invertedColumnMappings = invert(methods.watch("columnMappings"));
        let transformedArray = map(
          methods.watch("rawPayload"),
          (obj, index) => {
            const transformedObject = mapKeys(
              obj,
              (value, key) => invertedColumnMappings[key] || key
            );
            transformedObject.tempId = index;
            return transformedObject;
          }
        );
        // Validate rows. kinda inefficient to iterate again
        transformedArray = agentType.validateRows(transformedArray);
        methods.setValue("payload", transformedArray);
      },
    },
    {
      label: VERIFY_DATA_LABEL,
      optional: true,
      component: EditAgentList,
      componentProps: {
        rows: methods.watch("payload"),
        agentType: agentType,
        setValue: methods.setValue,
      },
      validationFields: ["payload"],
    },
    ...(agentType.type === accountsAgentType.type
      ? [
          {
            label: "Signals",
            optional: false,
            component: ConfigureSignals,
            componentProps: { control: methods.control },
            validationFields: [],
          },
        ]
      : []),
    {
      label: "Actions",
      optional: false,
      component: ConfigureActions,
      componentProps: {
        control: methods.control,
        tempEmail,
        setTempEmail,
        userMessageChannels,
        setUserMessageChannels,
      },
      validationFields: [],
    },
  ];

  // Special case for payload since it's tied to the datagrid which AFAIK isn't like a controllable form input
  useEffect(() => {
    methods.register("payload", {
      validate: {
        limitExceeded: (valueArray) => {
          const validRows = valueArray?.filter((value) => value.isValid);
          let countCompaniesToAdd = validRows?.length;
          if (methods.watch("syncResearchAccounts")) {
            const validRowsLength = validRows?.length || 0;
            const filteredRowsLength =
              filterDuplicateCompanies(validRows)?.length || 0;

            countCompaniesToAdd = validRowsLength + filteredRowsLength;
          }

          if (consumptionLimit && countCompaniesToAdd > monitorLimitRemaining) {
            return `You will exceed your ${
              agentType.singularText
            } quota of ${consumptionLimit} by ${
              countCompaniesToAdd - monitorLimitRemaining
            } ${
              countCompaniesToAdd - monitorLimitRemaining === 1
                ? agentType.singularText
                : agentType.displayName
            }. To upgrade your subscription, please contact sales@rule5.io.`;
          }
          return true;
        },
      },
    });
  }, [methods, activeStep, steps, monitorLimitRemaining, agentType, user]);

  /** Filter out opportunity research company if the user happens to have added it manually
   in create the form.  */
  const filterDuplicateCompanies = (userPayload) => {
    if (!userPayload || userPayload.length === 0) {
      return userOpportunityCompanies;
    }

    return userOpportunityCompanies.filter(
      (opportunityCompany) =>
        !userPayload.some(
          (payloadCompany) =>
            (payloadCompany.companyName &&
              payloadCompany.companyName ===
                opportunityCompany.companyInfo.name) ||
            (payloadCompany.companyDomain &&
              payloadCompany.companyDomain ===
                opportunityCompany.companyInfo.domain)
        )
    );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    // Perform step specific logic before validation
    steps[activeStep].onNext?.();
    // Validation
    const isStepValid = await methods.trigger(
      steps[activeStep]?.validationFields
    );
    if (isStepValid) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      // TODO this is terrible, probably should just remove the Verify data step or somthing
      let numStepsToMove = 1;
      if (
        steps[activeStep + 1].label === VERIFY_DATA_LABEL &&
        methods.watch("payload") &&
        !methods.watch("csvFile")
      ) {
        numStepsToMove = 2;
      }

      // Delay to ensure that focus out handler from the datagrid has a chance to
      // leave edit mode and include rows currently being edited into the payload
      // Alternatively, here in parent could try controlling the datagrid from EditAgentList
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + numStepsToMove);
        setSkipped(newSkipped);
      }, 100);
    }
  };

  const handleBack = () => {
    let numStepsToSkip = 1;
    // Skip back past verify data step in case csv upload was skipped
    if (steps[activeStep - 1].label === VERIFY_DATA_LABEL) {
      if (skipped.has(activeStep - 2)) {
        numStepsToSkip = 2;
      } else if (methods.watch("payload") && !methods.watch("csvFile")) {
        numStepsToSkip = 2;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - numStepsToSkip);
  };

  const handleSkip = () => {
    if (!steps[activeStep].optional) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    // Skip verify data step in case csv upload was skipped
    let numStepsToSkip = 1;
    if (steps[activeStep + 1].label === VERIFY_DATA_LABEL) {
      methods.resetField("payload"); // clear out any payload they may have manually entered
      numStepsToSkip = 2;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + numStepsToSkip);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      if (numStepsToSkip === 2) {
        newSkipped.add(activeStep + 1);
      }
      return newSkipped;
    });
  };

  const onSubmit = () => {
    setLoading(true);
    const newAgent = methods.watch();
    let params = cloneDeep(newAgent); // or could just do _.omit instead of below deletes
    delete params.columnMappings;
    delete params.csvFile;
    delete params.rawPayload;
    delete params.originalFileName;

    params.payload = filter(params.payload, { isValid: true });
    if (!isEmpty(params.payload)) {
      // Remove some temporary columns just used in the form which
      // should not be included in the request.
      // Also remove empty fields.
      params.payload = map(params.payload, (obj) => {
        const falseyRemoved = pickBy(obj, (value) => value);
        return pick(
          falseyRemoved,
          agentType.columns.map((column) => column.key)
        );
      });
    } else {
      delete params.payload;
    }

    if (params.syncResearchAccounts) {
      const payloadForRequest = params.payload || [];

      // Don't add a research company if the user happens to have added it manually
      // in create form.
      const opportunitiesToAdd = filterDuplicateCompanies(payloadForRequest);

      payloadForRequest.push(
        ...opportunitiesToAdd.map((opportunityCompany) => {
          return {
            companyId: opportunityCompany.companyId,
            companyName: opportunityCompany.companyInfo.name,
          };
        })
      );

      params.payload = payloadForRequest;
    }

    params.signals = signalObjectsToStrings(newAgent.signals);
    params.regions = regionObjectsToStrings(newAgent.regions);

    // Process emails array to remove invalid chips and collect pending input
    if (tempEmail.trim().length > 0) {
      const rawEmails = [];
      if (newAgent.action?.agentActionInfo?.notifications?.emails) {
        rawEmails.push(...newAgent.action.agentActionInfo.notifications.emails);
      }
      if (
        tempEmail &&
        tempEmail.trim().length > 0 &&
        validateEmailString(tempEmail)
      ) {
        rawEmails.push(tempEmail);
        setTempEmail("");
      }
      const processedEmails = rawEmails.reduce((result, currEmail) => {
        if (validateEmailString(currEmail)) {
          result.push(currEmail);
        }
        return result;
      }, []);
      params.action.agentActionInfo.notifications.emails = processedEmails;
      methods.setValue(
        "action.agentActionInfo.notifications.emails",
        processedEmails,
        { shouldDirty: true }
      );
    }
    // Process slack channels
    if (newAgent.action?.agentActionInfo?.notifications?.slackChannels) {
      params.action.agentActionInfo.notifications.slackChannels =
        newAgent.action.agentActionInfo.notifications.slackChannels.map(
          (channel) => channel.id
        );
    }
    axios
      .post(rule5properties.agents, params)
      .then((resp) => {
        setLoading(false);
        if (isSuccessStatus(resp.status)) {
          loadAgentRows();
          setResponse(resp.data);
          dialog.setModalTitle("Agent created");
        } else {
          const message = resp.data?.message;
          snackBar.createSnack(
            message ? message : "Unexpected error creating agent."
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        snackBar.createSnack("Unexpected error creating agent.");
        console.log(error);
      });
  };

  const ActiveComponent = steps[activeStep].component;
  const componentProps = steps[activeStep].componentProps;
  const invalidPayloadRowCount = methods
    .watch("payload")
    ?.filter((row) => !(row.isValid || row.isNew)).length;

  let skipText = "Skip this step";
  let nextText = "Next";
  if (
    steps[activeStep].label === VERIFY_DATA_LABEL &&
    invalidPayloadRowCount > 0
  ) {
    nextText = `Proceed without ${invalidPayloadRowCount} invalid row${
      invalidPayloadRowCount !== 1 ? "s" : ""
    }`;
  } else if (activeStep === steps.length - 1) {
    // TODO should final step be skippable then the button would need to be
    // updated to submit the form
    skipText = "Skip and finish";
  }

  return (
    <>
      {!response ? (
        <Stack gap="20px">
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={step.label} {...stepProps}>
                  <StepButton
                    color="inherit"
                    onClick={() => {
                      setActiveStep(index);
                    }}
                  >
                    {step.label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {defaultsLoading ? (
            <CircularProgress sx={{ alignSelf: "center" }} />
          ) : (
            <Box>
              <FormProvider {...methods}>
                <form>
                  <Box sx={{ overflow: "auto", maxHeight: "70vh" }}>
                    {loading ? (
                      <Stack
                        sx={{
                          height: "200px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Stack>
                    ) : (
                      <ActiveComponent {...componentProps} />
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={loading || activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {steps[activeStep].optional &&
                      steps[activeStep].label !== VERIFY_DATA_LABEL && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                          disabled={activeStep === steps.length - 1}
                        >
                          {skipText}
                        </Button>
                      )}

                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={loading}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={steps[activeStep].validationFields?.some(
                          (validationField) =>
                            Object.keys(methods.formState?.errors).includes(
                              validationField
                            )
                        )}
                      >
                        {nextText}
                      </Button>
                    )}
                  </Box>
                </form>
              </FormProvider>
            </Box>
          )}
        </Stack>
      ) : (
        <CongratsMessage
          agentType={agentType}
          response={response}
          loadAgentRows={loadAgentRows}
        />
      )}
    </>
  );
}
